.accordion-button {
  padding-right: 40px !important;
}
.swal2-container {
  z-index: 999999;
}
.set {
  background: rgb(127, 255, 127);
  color: rgb(2, 105, 2);
  text-align: center;
  padding: 30px;
  border-radius: 0 15px 0 15px;
  box-shadow: 2px 5px 5px #777;
  text-transform: uppercase;
  cursor: pointer;
}

.set1 {
  background: lightblue;
  color: rgb(3, 3, 110);
}

.set2 {
  color: rgb(134, 4, 26);
  background: pink;
}

.box {
  border-radius: 10px;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgb(3, 148, 177) 100%);
}

.examBtn {
  color: rgb(10, 5, 87);
  font-weight: 600;
  background: rgb(238, 174, 202);
  background: linear-gradient(90deg, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
  border-radius: 30px;
  cursor: pointer;
}

.page {
  /* background: url("../public/assets/background.gif"); */
  /* height: auto; */
  min-height: 90vh;
}

.navbar {
  background-color: rgb(4, 4, 95);
  padding: 10px 0;
  border-bottom: 3px solid #ffffff;
}

.nav-link {
  color: #ffffff !important;
  /* font-size: medium !important; */
  font-weight: 500 !important;
  cursor: pointer;
}

@media (max-width: 575px) {
  .nav-link {
    color: #000 !important;
  }
}

.navbar-toggler {
  background-color: #ffffff !important;
  margin: 3px !important;
  font-size: smaller !important;
}

.examComponent {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  border: 3px solid #f1f1f1;
  z-index: 2;
  padding: 0 !important;
}

.next {
  background-color: rgb(14, 185, 190);
  background-color: rgba(12, 245, 194, 0.4);
  padding: 20px;
  cursor: pointer;
  margin-bottom: 0;
}

.book {
  box-shadow: 1px 1px 0px #999,
    2px 2px 0px #999,
    3px 3px 0px #999,
    4px 4px 0px #999,
    5px 5px 0px #999,
    6px 6px 0px #999;

  background: linear-gradient(45deg, #6b6b6b, #011378);

  user-select: none;
}

.text-sm {
  font-size: small;
}

.cus-font {
  font-family: cursive;
}





.book-content {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1.1px 1.5px,
    rgba(0, 0, 0, 0.1) 0px 2.8px 3.9px, rgba(0, 0, 0, 0.08) 0px 5.8px 7.9px,
    rgba(0, 0, 0, 0.06) 0px 12.0455px 16.4px, rgba(0, 0, 0, 0.04) 0px 33px 45px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;

  user-select: none;
}



/* Book Cover Effect */
.book-content::before {
  content: "";
  position: absolute;
  inset: 0px;
  border-radius: 3px;
  pointer-events: none;
  filter: contrast(310%) brightness(100%);
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset;
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 0.118) 0.65%,
      rgba(255, 255, 255, 0.2) 1.53%,
      rgba(255, 255, 255, 0.1) 2.38%,
      rgba(0, 0, 0, 0.05) 3.26%,
      rgba(255, 255, 255, 0.14) 5.68%,
      rgba(244, 244, 244, 0) 6.96%);

  user-select: none;
}

.book-content:hover {
  background: #3fffc2;
}


.right-corner {
  bottom: 5px;
  position: absolute;
  right: 5px;
}

.file:hover {
  background: #abead6;
  cursor: pointer;
}

.file {
  background: #ededed;
  border-radius: 5px;
  user-select: none;
}

.bg-active {
  background: #3fffc2;
  border-radius: 5px;
  /* padding: 10px; */
}

.empty-file {
  border-style: dashed;
  padding: 20px;
  margin: 20px;
  min-height: 200px;
}

.form-label {
  font-weight: 700;
}

.loginForm {
  display: flex;
  height: 100vh;
}

.loginForm main {
  background: whitesmoke;
  padding: 20px;
  margin: auto;
  width: fit-content;
  border: 1px solid #1976d2;
  border-radius: 6px;
}

.bg-none {
  background: none;
  border: 0;
}

.show-password {}

.position-relative {
  position: relative;
}

.text-small {
  font-size: small;
}

.tableQuestionPaper .headers {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
}

.tableQuestionPaper .headers div {
  width: 50%;
}

.Question-paper .body .Answers div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.Question-paper .body .Answers div p {
  width: 50%;
}

.Question-paper .syllabus .headers p {
  border: 1px solid grey;
  padding: 12px;
  border-radius: 12px;
}

.space-between {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.edit-btn {
  background-color: #1976d2;
  border: 0;
  color: white;
  border-radius: 30px;
  padding: 5px 6px;
  display: inline;
  width: fit-content;
  height: fit-content;
}

.Question-paper .syllabus {
  border-bottom: 1px solid grey
}

.bg-theme {
  background-color: #709dc9;
}

.divider {
  border-bottom: 1px solid grey
}

.space-between.divider {
  margin-bottom: 20px;
}

.logo-dash img {
  width: 200px;
  height: fit-content;
}

.dashHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
}

.width-grid {
  /* width: 300px; */
  position: relative;
}

.width-grid .file.one {
  min-height: 120px;
  overflow: hidden;
  margin-top: 12px;
}

.width-grid .fileOne .fileTwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fileTwo {
  display: flex;
}

.fileTwo .float-ends {
  margin-left: auto;
}

.width-grid .fileOne .fileTwo .float-ends {
  position: absolute;
  top: 13px;
  right: 8px;
}

.filter-btn {
  border: 0;
}

.folderTextGrid {
  font-size: 12px;
  font-weight: 500;
}

.collapseSidebar {
  border: 0;
  background-color: lightcyan;
  border-radius: 12px;
}

.folder-stucture {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
}

.questionPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 160px;
}

.hover-pencil {
  position: absolute;
  top: -10px;
  right: 5px;
  background: lightslategrey;
  border-radius: 17px;
  padding: 4px 6px;
}
.hover-pencil:hover {
  background: rgb(136, 156, 175);
  border-radius: 18px;
}


/* .questionPreviewText {
  position: absolute;
  bottom: 10px;
} */

/* name date , asceding descending */

.graphView {
  /* width: 100%; */
  background-color: lightcyan;
  border: 1px solid grey;
  height: 20px;
  overflow: hidden;
}

.occupy7 {
  /* background-color: darkcyan; */
  height: 19px
}

.relative {
  position: relative;
}

.def {
  display: none;

  position: absolute;
  top: 0;
  background-color: #262824a1;
  color: rgb(255, 255, 255);
  width: 100%;
}

.graphView:hover .def {
  display: block;
}

.ans-edit{
  position: relative;
}

.report-table-height{
  min-height: 50px;
  /* border-bottom: 1px solid black; */
  display: flex;
  align-items: center;
}
math-field {
  width:  100%;
}
.inline-600 {
  inline-size: 600px;
  inline-size: 600px;
  max-height: 100px;
  overflow: hidden;
  text-overflow: clip;

}
.examsLoader {
  display: flex;
}
.examsLoader .exams_categories p{
  background: lightcyan;
  padding: 12px;
  font-weight: 500;

}